import axios from '@/plugins/axios'
import { ExchangeConnectReq } from './models'
import { BaseResponse } from '../base'

const RESOURCES = Object.freeze({
  exchangeConnect: '/user/secret',
  otp: '/user/otp',
  device: '/user/device'
})

export default {
  connectExchange(data: ExchangeConnectReq) {
    return axios.post<BaseResponse<boolean>>(RESOURCES.exchangeConnect, data)
  },

  generateOtpQRCode() {
    return axios.post<BaseResponse<string>>(`${RESOURCES.otp}/generate`)
  },

  addOtp(data: { otp: string }) {
    return axios.post<BaseResponse<any>>(`${RESOURCES.otp}/add`, data)
  },

  removeOtp(data: { otp: string }) {
    return axios.delete<BaseResponse<any>>(`${RESOURCES.otp}/remove`, {
      data
    })
  },

  getDeviceVerify() {
    return axios.get<BaseResponse<any>>(`${RESOURCES.device}/verify`)
  },

  verifyDevice(data: { otp: string }) {
    return axios.post<BaseResponse<any>>(`${RESOURCES.device}/verify`, data)
  }
}
