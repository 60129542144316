















































import Vue from 'vue'
import { mapMutations } from 'vuex'
import ExchangeService from '@/services/exchange'
import UserService from '@/services/user'
import { Exchange } from '@/services/exchange/models'
import { ExchangeConnectReq } from '@/services/user/models'
import { SelectOption } from '@/models/form'
import { MESSAGE_STATUS } from '@/store/modules/message/types'

export default Vue.extend({
  data(): {
    exchangeConnectRequest: ExchangeConnectReq
    exchanges: Exchange[]
    isLoading: boolean
  } {
    return {
      exchangeConnectRequest: {
        exchange: '',
        apiPublic: '',
        apiPrivate: '',
        apiPass: undefined
      },
      exchanges: [],
      isLoading: false
    }
  },

  computed: {
    exchangeOptions(): SelectOption[] {
      return this.exchanges.map((exchange: Exchange) => ({
        value: exchange.name,
        label: exchange.name
      }))
    },

    rules(): Record<string, any[]> {
      return {
        exchange: [{ required: true, message: 'Please select exchange', trigger: 'change' }],
        apiPublic: [{ required: true, message: 'Please input API Key', trigger: 'blur' }],
        apiPrivate: [{ required: true, message: 'Please input API Secret', trigger: 'blur' }],
        apiPass: this.isCoinbaseExchange ? [{ required: true, message: 'Please input API Pass', trigger: 'blur' }] : []
      }
    },

    isCoinbaseExchange(): boolean {
      return this.exchangeConnectRequest.exchange.toUpperCase().includes('COINBASE')
    }
  },

  methods: {
    ...mapMutations('message', ['SET_MESSAGE']),

    async getActiveExchanges() {
      try {
        const { data: response } = await ExchangeService.getActiveExchanges()
        if (response.success && response.data && response.data.length > 0) {
          this.exchanges = response.data
          this.exchangeConnectRequest.exchange = this.exchanges[0].name
        }
      } catch (_) {}
    },

    connectExchange() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line space-before-function-paren
      this.$refs.connectForm.validate(async (valid: boolean) => {
        if (valid) {
          try {
            this.isLoading = true
            const response = await UserService.connectExchange(this.exchangeConnectRequest)
            if (response.data) {
              // show notification
              this.SET_MESSAGE({
                type: MESSAGE_STATUS.SUCCESS,
                message: `Connect ${this.exchangeConnectRequest.exchange} success!`
              })
            }
          } finally {
            this.isLoading = false
          }
        } else {
          return false
        }
      })
    }
  },

  created() {
    this.getActiveExchanges()
  }
})
